export const tableConfig = {
  data () {
    return {
      containerWidth: 0,
      loading: false,
      page: 1,
      pageSize: 50,
      pageSizes: [50, 100, 250],
      tableHeight: 0,
      serverItemsLength: 0,
      showDialog: false,
      nonTableHeight: 0,
      windowHeight: 0,
      disablePrevious: true,
      disableNext: false
    }
  },

  watch: {
    windowHeight: {
      immediate: true,
      handler () {
        this.nonTableHeight = this.windowHeight - this.tableHeight
      }
    },

    nonTableHeight: {
      immediate: true,
      handler () {
        this.tableHeight = this.windowHeight - this.nonTableHeight
      }
    },
  },

  created () {
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', this.resize)
    this.resize()
  },

  methods: {
    resize () {
      this.windowHeight = window.innerHeight
      this.tableHeight = this.windowHeight - this.nonTableHeight
    }
  }
}
